<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UNE") : $t("MODIFICATION_DE_L") }} AFFECTATION </h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <p class="pt-4">{{ $t("staff") }}<b style="color: #ff6141" >*</b> </p>
          <vs-select
            :closeOnSelect="true"
            data-vv-validate-on="blur"
            autocomplete
            v-model="input.staff"
            class="w-full "
            name="staff"
           >
          <vs-select-item :key="item" :value="item.id" :text="item.fullName" v-for="item in staffs" />
          </vs-select>
          <span class="text-danger text-sm" v-show="errors.has('staff')">{{$t("staff_error")}}</span>
           
          <p class="pt-4">{{ $t("equipments") }}<b style="color: #ff6141" >*</b> </p>
          <vs-select
                multiple 
                :closeOnSelect="false"
                v-model="input.equipments"
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="equipments"
                autocomplete
                class="w-full"
            >
          <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in equipments_list" /></vs-select>
          
          <span class="text-danger text-sm" v-show="errors.has('equipments')">{{$t("staffHasEquipmentEquipments_error")}}</span>
           
        </div>
      </component>
  
      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="category_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
      </div>
    </vs-sidebar>
  </template>
  
<script>
  
// For custom error message
import { Validator } from 'vee-validate'
  
const dict = {
  custom: {
  
    staff: {
      required: 'Le champ personnel est obligatoire'
    }
  }
}
  
// register custom messages
Validator.localize('en', dict)
  
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  
const input_tempon = {
  staff: '',
  equipments:[]
}
  
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      id:'',
      staffs:[],
      equipments_list:[],
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.input.equipments = []
        this.input.id = this.data.id
        this.input.staff = this.data.staff.id
        for (let i = 0; i < this.data.equipements.length; i++) {
          this.input.equipments.push(this.data.equipements[i].id)
         
        }
     
        this.initValues()
      }
    }
  },
  
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
    },
    category_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
  
    async submitData () {
      this.$vs.loading()
      const input = JSON.parse(JSON.stringify(this.input))
  
      let url = 'staffHasEquipments/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('staffHasEquipment_save')
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = this.$t('staffHasEquipment_update')
      }
  
      this.$http[methods](url, input)
        .then((response) => {
          window.staffHasEquipments.getAllstaffHasEquipments()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'name') {
              libelle = this.$t('task')
            }
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
  
          window.getPrendTaCom.error(message.error)
        })
    },
    getAllstaffs () {
      this.$vs.loading()
      this.$http.get('staffs/')
        .then((response) => {
          this.staffs = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllequipments () {
      this.$vs.loading()
      this.$http.get('equipments/')
        .then((response) => {
          //this.equipments_list = response.data
          let as = ''
          const results = response.data
          results.forEach(event => {
            
            if (event.assigned) {
              as = 1
            } else {
              as = 0
            }
            this.equipments_list.push({
              id: event.id,
              name:`${event.equipmentType.name}-(${event.number})-(${as})`
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  
  },
  created () {
    this.getAllstaffs()
    this.getAllequipments()

  }
}
</script>
  
  <style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }
  
    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;
  
      .img-upload {
        margin-top: 2rem;
  
        .con-img-upload {
          padding: 0;
        }
  
        .con-²-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  
  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  
    &:not(.ps) {
      overflow-y: auto;
    }
  }
  </style>
  